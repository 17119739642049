import qs from "qs";
import { useStrapiApi } from "~/uses/useMyFetch";

export const fetchReviews = (productCode, page, pageSize) => {
  const query = qs.stringify({
    sort: ["createdAt:desc"],
    fields: ["rating", "content", "pros", "cons", "reply", "name", "createdAt"],
    filters: {
      productCode,
    },
    pagination: {
      page,
      pageSize,
    },
  });

  return useStrapiApi(`/product/reviews?${query}`, {
    timeout: 4000,
  });
};
